* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --note-border-radius: 6px;
}

.images-loaded .grid {
  opacity: 1;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-transform: uppercase;
  transition: opacity 0.6s linear 0.4s;
  color: #e8eaed;
}

.images-loaded .loading {
  opacity: 0;
  display: none;
}

.item {
  z-index: 1;
}

.item.muuri-item-positioning {
  z-index: 2;
}

.item.muuri-item-dragging,
.item.muuri-item-releasing {
  z-index: 9999;
}

.item.muuri-item-dragging {
  cursor: move;
}

.item.muuri-item-hidden {
  z-index: 0;
}

.item.muuri-item-placeholder {
  z-index: 2;
  margin: 0;
  opacity: 0.5;
}